import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Merchant } from '../models/merchant';
import { SearchTermService } from '../services/search-term.service';
import { User } from '../models/user';
import { UserService } from '../services/user.service';
import { Role } from '../models/role';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { Paginator } from '../models/paginator';

@Component({
  selector: 'app-list-merchants',
  templateUrl: './list-merchants.component.html',
  styleUrls: ['./list-merchants.component.sass']
})
export class ListMerchantsComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['mid', 'name', 'locatorId', 'gatewayName', 'partnerName', 'isActive', 'createdDateUtc'];
  dataSource = new MatTableDataSource<Merchant>();
  user: User;
  isLoading = false;
  paginator = new Paginator();

  @ViewChild(MatPaginator, {static: true}) matPaginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) matSort: MatSort;
  @ViewChild('search') input: ElementRef<HTMLInputElement>;

  constructor(
    private router: Router,
    private searchTermService: SearchTermService,
    private userService: UserService
  ) {
    this.user = this.userService.currentUser;
  }

  ngOnInit(): void {
    this.dataSource.sort = this.matSort;

    this.applyFilter(this.searchTermService.getTerm());
  }

  ngAfterViewInit() {
    // adding deboucing time for search input to avoid unecessary requests while typing
    fromEvent(this.input.nativeElement,'keyup')
    .pipe(
      filter(Boolean),
      debounceTime(400),
      distinctUntilChanged(),
      tap((text) => {
        this.applyFilter(this.input.nativeElement.value as string);
      })
    )
    .subscribe();

    this.input.nativeElement.value = this.searchTermService.getTerm();
  }

  load() {
    this.isLoading = true;

    this.searchTermService.search(this.paginator).subscribe({
      next: (data) => {
        this.dataSource.data = data.merchants;

        this.paginator.currentPage = data.search.page;
        this.paginator.pageSize = data.search.limit;
        this.paginator.total = data.total;
        this.isLoading = false;
      },
      error: () => this.isLoading = false,
    })
  }

  applyFilter(filterValue: string) {
    this.searchTermService.saveTerm(filterValue);
    this.load();
  }

  goToEditPage(merchant: Merchant){
    this.router.navigate([`/merchants`, merchant.mid]);
  }

  pageChanged(event: PageEvent) {
    this.paginator.pageSize = event.pageSize;
    this.paginator.currentPage = event.pageIndex;
    this.load();
  }

  sortData(sort: Sort) {
    this.paginator.sort = sort.active;
    this.paginator.order = sort.direction;
    this.load();
  }


  isEnabled(value: string): boolean {
    switch (this.user?.Role) {
      case Role.Admin:
      case Role.Maintainer:
        return true;
      default:
        return false;
    }
  }
}
