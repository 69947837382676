<mat-card>
  <mat-card-title>
    <div fxLayoutAlign="start" fxFlex="20%">
      Merchants
    </div>
    <div fxLayoutAlign="start" fxFlex="50%">
      <mat-form-field fxFill>
        <mat-label>Filter</mat-label>
        <input matInput placeholder="Filter" #search>
      </mat-form-field>
    </div>
    <div fxLayoutAlign="end" fxFlex="30%">
      <a routerLink="/merchant" color="accent" mat-raised-button routerLinkActive="active" *ngIf="isEnabled('create')">Create New Merchant</a>
    </div>
  </mat-card-title>

  <div id="list">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSource" class="mat-elevation-z8">
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- MID Column -->
      <ng-container matColumnDef="mid">
        <mat-header-cell *matHeaderCellDef mat-sort-header> MID </mat-header-cell>
        <mat-cell *matCellDef="let merchant">
          <a routerLink="/merchants/{{ merchant.mid }}">{{ merchant.mid }}</a>
        </mat-cell>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
        <mat-cell *matCellDef="let merchant">
          <a routerLink="/merchants/{{ merchant.mid }}">{{ merchant.name }}</a>
        </mat-cell>
      </ng-container>

      <!-- LocatorId Column -->
      <ng-container matColumnDef="locatorId">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Locator Id </mat-header-cell>
        <mat-cell *matCellDef="let merchant">
          <a routerLink="/merchants/{{ merchant.mid }}">{{ merchant.locatorId }}</a>
        </mat-cell>
      </ng-container>

      <!-- Gateway Column -->
      <ng-container matColumnDef="gatewayName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Gateway </mat-header-cell>
        <mat-cell *matCellDef="let merchant">
          <a routerLink="/merchants/{{ merchant.mid }}">{{ merchant.gatewayName }}</a>
        </mat-cell>
      </ng-container>

      <!-- Partner Name Column -->
      <ng-container matColumnDef="partnerName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Partner Name </mat-header-cell>
        <mat-cell *matCellDef="let merchant">
          <a routerLink="/merchants/{{ merchant.mid }}">{{ merchant.partnerName }}</a>
        </mat-cell>
      </ng-container>

      <!-- IsActive Column -->
      <ng-container matColumnDef="isActive">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Is Active</mat-header-cell>
        <mat-cell *matCellDef="let merchant">
          <a routerLink="/merchants/{{ merchant.mid }}">{{merchant.isActive}}</a>
        </mat-cell>
      </ng-container>

      <!-- Created Date Time Column -->
      <ng-container matColumnDef="createdDateUtc">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Created Date Time </mat-header-cell>
        <mat-cell *matCellDef="let merchant">
          <a routerLink="/merchants/{{ merchant.mid }}">{{ merchant.createdDateUtc * 1000| date:'yyyy-MM-dd hh:mm:ss a z' }}</a>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <mat-paginator [length]="paginator.total" [pageIndex]="paginator.currentPage" [pageSize]="paginator.pageSize"
      [pageSizeOptions]="paginator.pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page" showFirstLastButtons></mat-paginator>
  </div>
</mat-card>
